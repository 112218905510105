import { useLogout } from "@/components/hooks/useLogout";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React from "react";

type LogoutProps = {};

const Logout: React.FC<LogoutProps> = () => {
  const logout = useLogout();

  return (
    <ListItem button onClick={logout}>
      <ListItemIcon>
        <LogoutRoundedIcon />
      </ListItemIcon>
      <ListItemText primary="Uitloggen" />
    </ListItem>
  );
};

export default Logout;
