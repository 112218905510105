import { SquareLogoQuery } from "@/gatsbyGraphqlTypes";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

type SquareLogoProps = {};

const SquareLogo: React.FC<SquareLogoProps> = () => {
  const {
    file: {
      childImageSharp: { gatsbyImageData: logo },
    },
  } = useStaticQuery<SquareLogoQuery>(graphql`
    query SquareLogo {
      file(relativePath: { eq: "logo_300px.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 220, aspectRatio: 1)
        }
      }
    }
  `);

  return logo ? <GatsbyImage image={logo} alt="logo" /> : null;
};

export default SquareLogo;
