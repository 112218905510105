import Box from "@mui/material/Box";
import React from "react";
import SEO from "../generic/seo";
import AuthCheck from "./authCheck";
import MenuDrawer, { drawerWidth } from "./menuDrawer";

type LayoutProps = {
  title?: string;
};

const Layout: React.FC<LayoutProps> = ({ children, title }) => {
  return (
    <>
      <SEO title={title} />
      <AuthCheck>
        <Box sx={{ display: "flex", px: { xs: 1, sm: 2, md: 4 }, py: 8 }}>
          <MenuDrawer />
          <Box component="main" sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
            {children}
          </Box>
        </Box>
      </AuthCheck>
    </>
  );
};

export default Layout;
