import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import React, { useState } from "react";
import Menu from "./menu";
import ToggleButton from "./toggleButton";

type MenuDrawerProps = {};

export const drawerWidth = 246;

const MenuDrawer: React.FC<MenuDrawerProps> = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const container = window !== undefined ? window.document.body : undefined;

  const handleDrawerToggle = () => setMobileOpen((s) => !s);

  return (
    <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}>
      <ToggleButton
        open
        onClick={handleDrawerToggle}
        sx={{
          position: "absolute",
          left: 8,
          top: 8,
        }}
      />
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        color="secondary"
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          position: "relative",
          display: { xs: "block", md: "none" },
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            boxSizing: "border-box",
            width: drawerWidth,
            bgcolor: "secondary.main",
          },
        }}
      >
        <ToggleButton
          open={false}
          onClick={handleDrawerToggle}
          sx={{
            position: "absolute",
            right: -2,
            top: 1,
            zIndex: ({ zIndex }) => zIndex.modal,
          }}
        />
        <Menu />
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", md: "block" },
        }}
        PaperProps={{
          sx: {
            boxSizing: "border-box",
            width: drawerWidth,
            bgcolor: "secondary.main",
          },
        }}
        open
        color="secondary"
      >
        <Menu />
      </Drawer>
    </Box>
  );
};

export default MenuDrawer;
