import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { SxProps, Theme } from "@mui/material/styles";
import React from "react";

type ToggleButtonProps = {
  open: boolean;
  sx?: SxProps<Theme>;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const ToggleButton: React.FC<ToggleButtonProps> = ({ open, sx, onClick }) => {
  return (
    <Box sx={sx}>
      <IconButton color="primary" size="large" onClick={onClick}>
        {open ? <MenuRoundedIcon /> : <CloseRoundedIcon />}
      </IconButton>
    </Box>
  );
};

export default ToggleButton;
