import AccountBoxRoundedIcon from "@mui/icons-material/AccountBoxRounded";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import ChatRoundedIcon from "@mui/icons-material/ChatRounded";
import ContactSupportRoundedIcon from "@mui/icons-material/ContactSupportRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "gatsby";
import React, { ElementType } from "react";
import SquareLogo from "../../generic/squareLogo";
import Logout from "./logout";

type MenuProps = {};

type MenuType = {
  label: string;
  url: string;
  Icon: ElementType;
};

const menu: MenuType[] = [
  {
    label: "Dashboard",
    url: "/",
    Icon: HomeRoundedIcon,
  },
  {
    label: "Account",
    url: "/account/",
    Icon: AccountBoxRoundedIcon,
  },
  {
    label: "Verbruik",
    url: "/verbruik/",
    Icon: AssessmentRoundedIcon,
  },
  {
    label: "Berichten",
    url: "/berichten/",
    Icon: ChatRoundedIcon,
  },
  {
    label: "Support",
    url: "/support/",
    Icon: ContactSupportRoundedIcon,
  },
];

const Menu: React.FC<MenuProps> = () => (
  <div>
    <Box
      component="a"
      href="https://mddd.nl"
      target="_blank"
      rel="noopener"
      sx={{ widht: "100%", display: "flex", justifyContent: "center", alignItems: "center", pr: 2 }}
    >
      <SquareLogo />
    </Box>
    <Divider />
    <List>
      {menu.map(({ label, url, Icon }) => (
        <ListItem button key={label} component={Link} to={url}>
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          <ListItemText primary={label} />
        </ListItem>
      ))}
      <Logout />
    </List>
  </div>
);

export default Menu;
