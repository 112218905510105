import { useUserQuery } from "@/generated";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import React, { useEffect } from "react";
import AbsoluteCenter from "../generic/absoluteCenter";

type AuthCheckProps = {};

const AuthCheck: React.FC<AuthCheckProps> = ({ children }) => {
  const { data, loading } = useUserQuery();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!loading && data?.authenticatedItem === null) {
      navigate(`/login/`, { state: { redirect: pathname } });
    }
  }, [data?.authenticatedItem, loading, pathname]);

  if (!data?.authenticatedItem) {
    return (
      <AbsoluteCenter>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress size={72} />
        </Box>
      </AbsoluteCenter>
    );
  }

  return <>{children}</>;
};

export default AuthCheck;
